<template>
  <div class="hall-index">
    <!--首页轮播大图幻灯区-->
    <div class="hall-index-shuffling">
      <div class="container">
        <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide"><img src="../../assets/images/hall/hall-index-banner.png" /></div>
            </div>
          </div>
      </div>
    </div>
    <!--创建馆-->
    <div class="hall-index-create">
      <div class="container">
        <div class="create-box">
          <div class="box-lef"><img src="../../assets/images/hall/hall-index-banner2.png" /></div>
          <div class="box-rig">
            <div class="box-rig-top">
              <div class="rig-top-avter">
                <img src="../../assets/images/hall/hall-index-banner2.png">
              </div>
              <div class="rig-top-name">微信名</div>
              <div class="rig-top-btn"><button>创建馆</button></div>
            </div>
            <div class="box-rig-bot">
              <div class="box-rig-bot-title">
                <div class="text-center title">馆搜索</div>
                <div class="text-center logo"><img src="../../assets/images/hall/hall-index-title-logo-lef.png" /><img src="../../assets/images/hall/hall-index-title-logo-rig.png" /></div>
              </div>
              <div class="box-rig-bot-box">
                <div class="box-search">
                  <div class="search-input"><input placeholder="搜索" /></div>
                  <button type="button"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--我的相关馆-->
    <div class="hall-index-pavilion">
      <div class="container">
        <div class="hall-box-title">
          <div class="text-center title">我的相关馆</div>
          <div class="text-center logo">
            <img class="img-lef" src="../../assets/images/hall/hall-index-title-logo-lef.png" />
            <img class="img-rig" src="../../assets/images/hall/hall-index-title-logo-rig.png" />
          </div>
        </div>
        <div id="certify">
          <div class="swiper-container-pavilion">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="hall-avter"><img src="../../assets/images/hall/hall-index-banner2.png" /></div>
                <div class="hall-name">张某系</div>
                <div class="hall-date">1985.01.23-2018.02.24</div>
              </div>
              <div class="swiper-slide">
                <div class="hall-avter"><img src="../../assets/images/hall/hall-index-banner2.png" /></div>
                <div class="hall-name">张某某</div>
                <div class="hall-date">1985.01.23-2018.02.24</div>
              </div>
              <div class="swiper-slide">
                <div class="hall-avter"><img src="../../assets/images/hall/hall-index-banner2.png" /></div>
                <div class="hall-name">离婚后</div>
                <div class="hall-date">1985.01.23-2018.02.24</div>
              </div>
              <div class="swiper-slide">
                <div class="hall-avter"><img src="../../assets/images/hall/hall-index-banner2.png" /></div>
                <div class="hall-name">砸啊在</div>
                <div class="hall-date">1985.01.23-2018.02.24</div>
              </div>
              <div class="swiper-slide">
                <div class="hall-avter"><img src="../../assets/images/hall/hall-index-banner2.png" /></div>
                <div class="hall-name">欠侵权</div>
                <div class="hall-date">1985.01.23-2018.02.24</div>
              </div>
            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div class="hall-box-more">
          <a class="color-white">MORE+<span class="sea-iconfont icon-jiantou"></span></a>
        </div>
      </div>
    </div>
    <!--海葬名人馆-->
    <div class="hall-index-celebrity">
      <div class="container">
        <div class="hall-box-title">
          <div class="text-center title">海葬名人馆</div>
          <div class="text-center logo">
            <img class="img-lef" src="../../assets/images/hall/hall-index-title-logo-lef.png" />
            <img class="img-rig" src="../../assets/images/hall/hall-index-title-logo-rig.png" />
          </div>
        </div>
        <div class="hall-box-group">
          <div class="row">
            <div v-for="index in 6" class="col-sm" :class="[(index % 2) == 0 ? 'double' : 'single']">
              <div class="group-item" v-if="(index % 2) == 0">
                <router-link to="/hall/palace-hall">
                  <div class="item-name">赵忠祥</div>
                  <div class="item-date">1942.01.16-2020.01.16</div>
                  <div class="item-btn"><button>进馆祭拜</button></div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div v-for="index in 6" class="col-sm group-item" :class="[(index % 2) == 0 ? 'single' : 'double']">
              <div class="group-item" v-if="(index % 2) != 0">
                <div class="item-name">赵忠祥</div>
                <div class="item-date">1942.01.16-2020.01.16</div>
                <div class="item-btn"><button>进馆祭拜</button></div>
              </div>
            </div>
          </div>
        </div>
        <div class="hall-box-more">
          <a class="color-white">MORE+<span class="sea-iconfont icon-jiantou"></span></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'swiper/dist/css/swiper.css'
  import Swiper from 'swiper/dist/js/swiper.js'

  export default {
    name: 'hall-index',
    mounted() {
      const that = this;
      that.swiper = new Swiper('.swiper-container', {
        observeParents: false,   //注意这里！！
        observer: true, //也要注意这里！！
      });
      that.certifySwiper = new Swiper('#certify .swiper-container-pavilion', {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: false,
        loopedSlides: 5,
        initialSlide: 2,
        autoplay: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          progress: function(progress) {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              var modify = 1;
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
              }
              var translate = slideProgress * modify * 60 + 'px';
              var scale = 1 - Math.abs(slideProgress) / 5;
              var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.transform('translateX(' + translate + ') scale(' + scale + ')');
              slide.css('zIndex', zIndex);
              slide.css('opacity', 1);
              if (Math.abs(slideProgress) > 3) {
                slide.css('opacity', 0);
              }
            }
          },
          setTransition: function(transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i)
              slide.transition(transition);
            }

          }
        }
      })
    }
  }
</script>

<style lang="scss" scoped>
.hall-index {
  margin-top: 82px;
  width: 100%;
  position: relative;
  z-index: 2;
  .hall-box-title {
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #0657A2;
    }
    .logo {
      margin-top: -10px;
      .img-lef {
        margin-right: 30px;
      }
      .img-rig {
        margin-left: 30px;
      }
    }
  }
  .hall-box-more {
    text-align: center;
    .color-white {
      font-size: 24px;
      font-weight: bold;
      color: #0758A2;
      .sea-iconfont {
        margin-left: 6px;
        font-size: 24px;
      }
      &:hover {
        color: #000000;
       }
    }
  }
  .hall-index-celebrity {
    width: 100%;
    position: relative;
    z-index: 2;
    .container {
      background: rgba(247,251,255,1);
      padding: 30px 20px;
      .hall-box-group {
        width: 100%;
        margin: 30px 0;
        overflow: hidden;
        .group-item {
          .item-name {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #353535;
            margin: 24px 0 12px 0;
          }
          .item-date {
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #353535;
            margin-bottom: 12px;
          }
          .item-btn {
            text-align: center;
            button {
              width: 150px;
              height: 40px;
              font-size: 14px;
              font-weight: bold;
              color: #2C74B9;;
              border: none;
              background: url("../../assets/images/hall/hall-index-button.png") no-repeat center;
              background-size: cover;
              border-radius: 20px;
            }
          }
        }
        .single {
          width: 158px;
          height: 158px;
          background: #E3E1E0;
          margin-left: 5px;
        }
        .double {
          padding: 0 20px;
        }
      }
    }
  }
  .hall-index-pavilion {
    width: 100%;
    position: relative;
    z-index: 2;
    .container {
      padding: 24px 20px;
      background: #E8EDF3;
      #certify {
        position: relative;
        width: 96%;
        overflow: hidden;
        margin: 0 auto
      }

      #certify .swiper-container-pavilion {
        padding: 28px 0;
      }

      #certify  .swiper-slide {
        width: 228px;
        height: 247px;
        background: #fff;
        box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.23);
        .hall-avter {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 40px;
          margin-bottom: 14px;
          img {
            width: 87px;
            height: 87px;
            background: #FFFFFF;
            border: 1px solid #FFFFFF;
            box-shadow: 1px 5px 5px 0px rgba(6, 0, 1, 0.23);
            border-radius: 50%;
          }
        }
        .hall-name {
          text-align: center;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
        .hall-date {
          text-align: center;
          font-size: 16px;
          margin-top: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
      }
      #certify  .swiper-slide-active {
        background: linear-gradient(111deg, #1E66BA, #5497E6) !important;
        .hall-name {
          color: #ffffff;
        }
        .hall-date {
          color: #ffffff;
        }
      }
      #certify .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 5px;
        border: 3px solid #fff;
        background-color: #d5d5d5;
        width: 10px;
        height: 10px;
        opacity: 1;
      }
      #certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
        border: 3px solid #00aadc;
        background-color: #fff;
      }
      #certify .swiper-button-prev {
        left: 0;
        width: 30px;
        height: 30px;
        background: url('../../assets/images/hall/wm_button_icon.png') no-repeat;
        background-position: 0 0;
        background-size: 100%;
      }
      #certify .swiper-button-prev:hover {
        background-position: 0 -31px;
        background-size: 100%
      }
      #certify .swiper-button-next {
        right: 0;
        width: 30px;
        height: 30px;
        background: url('../../assets/images/hall/wm_button_icon.png') no-repeat;
        background-position: 0 -62px;
        background-size: 100%;
      }
      #certify .swiper-button-next:hover {
        background-position: 0 -92px;
        background-size: 100%
      }
    }
  }
  .hall-index-create {
    width: 100%;
    position: relative;
    z-index: 2;
    .container {
      padding: 0 20px;
      background: rgba(247,251,255,1);
      .create-box {
        display: flex;
        flex-wrap: wrap;
        .box-lef {
          margin: 32px 0;
          width: 609px;
          height: 362px;
          img {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          }
        }
        .box-rig {
          flex: 1;
          position: relative;
          margin-top: 32px;
          margin-left: 20px;
          .box-rig-bot {
            position: absolute;
            width: 100%;
            bottom: 0;
            .box-rig-bot-title {
              .title {
                font-size: 20px;
                font-weight: bold;
                color: #0657A2;
              }
              .logo {
                margin-top: -10px;
              }
            }
            .box-rig-bot-box {
              height: 116px;
              background: #FFFFFF;
              border: 1px solid;
              margin-top: -3px;
              border-image: linear-gradient(0deg, #79AAD9, #407DB2) 10 10 0 10;

              .box-search {
                display: flex;
                margin-top: 30px;
                justify-content: center;
                .search-input {
                  width: 245px;
                  height: 40px;
                  background: #FFFFFF;
                  border: 1px solid #407DB2;
                  padding: 4px 13px;
                  border-radius: 19px 0px 0px 21px;
                  input {
                    width: 100%;
                    border: none;
                    line-height: 26px;
                  }
                }
                button {
                  width: 66px;
                  height: 40px;
                  border: 1px solid;
                  /* border-image: linear-gradient(0deg, #79AAD9, #407DB2) 10 10; */
                  background: linear-gradient(90deg, #79AAD9 0%, #407DB2 100%);
                  border-radius: 0px 20px 20px 0px;
                  border: none;
                }
              }
            }
          }
          .box-rig-top {
            width: 100%;
            height: 205px;
            background: #FFFFFF;
            display: flex;
            flex-wrap: wrap;
            border: 1px solid;
            border-image: linear-gradient(0deg, #79AAD9, #407DB2) 10 10;
            .rig-top-avter {
              width: 100%;
              margin-top: 20px;
              text-align: center;
              img{
                width: 80px;
                height: 80px;
                overflow: hidden;
                border: none;
                border-radius: 50%;
              }
            }
            .rig-top-name {
              width: 100%;
              text-align: center;
              font-size: 16px;
              font-weight: bold;
              color: #050505;
              line-height: 16px;
              height: 16px;
            }
            .rig-top-btn {
              width: 100%;
              text-align: center;
              button {
                width: 310px;
                height: 40px;
                background: linear-gradient(-90deg, #79AAD9 0%, #407DB2 100%);
                border-radius: 20px;
                border: none;
                font-size: 20px;
                font-weight: bold;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
  .hall-index-shuffling {
    width: 100%;
    position: relative;
    z-index: 2;
    .container {
      padding: 0;
      .swiper-container {
        width: 100%;
        height: 532px;
        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          img {
            width: 100%;
            overflow: hidden;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>
